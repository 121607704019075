<template>
  <div class="vg_wrapper" v-loading="loadingFlag">
    <el-card>
      <div class="vg_button_group">
        <span v-if="disabled === true" class="vd_disFlex">
          <el-button type="success" @click="openEdit" size="small" :disabled="!btn.edit">编辑</el-button>
          <el-button
            type="warning"
            :disabled="(userForm.dyj_user_id !== 0 && userForm.dyj_user_id !== null) || userForm.delete_time !== 0"
            @click="synchronization"
            size="small"
            >生成打样间账号</el-button
          >
          <el-button
            type="warning"
            plain
            :disabled="(userForm.mes_user_id !== 0 && userForm.mes_user_id !== null) || userForm.delete_time !== 0"
            @click="synchronizationS"
            size="small"
            >生成MES账号</el-button
          >
          <!-- <el-button type="warning" size="small" @click="synchronizationLogin()">同步登录</el-button> -->
          <el-button type="danger" :disabled="userForm.delete_time === 0" plain size="small" @click="recovery()">恢复</el-button>
        </span>
        <span v-if="disabled === false">
          <el-button type="primary" @click="submit()" size="small">保存</el-button>
          <el-button @click="closeEdit" size="small">取消</el-button>
        </span>
      </div>
      <el-form ref="userForm" :model="userForm" size="mini" class="vg_partition">
        <el-row type="flex" justify="space-around">
          <el-col :md="4">
            <el-form-item label="账号" prop="user_name" required>
              <el-input v-model="userForm.user_name" :disabled="disabledFn"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="4">
            <el-form-item label="密码">
              <el-input v-model="password" show-password :disabled="disabledFn">
                <template slot="append">
                  <el-link type="danger" @click="resetPassBox()" :disabled="disabled">重置</el-link>
                </template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :md="4">
            <el-form-item label="姓名" :disabled="disabledFn" prop="stff_name" required>
              <select-input
                :selTableData="selTableData"
                :tableHeader="staffTableHeader"
                @getSelTableRow="getSelTableRow"
                :inputVal="stffForm.stff_name"
                :disabled="disabled"
                :rowSelect="rowSelect"
                :isShowCol="false"
                placeholder="请选择员工姓名"
              >
              </select-input>
            </el-form-item>
          </el-col>
          <el-col :md="4">
            <el-form-item label="部门" prop="dept_name" required>
              <el-input v-model="stffForm.dept_name" :disabled="disabledFn" placeholder="员工所在部门"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="4">
            <el-form-item label="小组" prop="dept_team_name">
              <el-input v-model="stffForm.dept_team_name" :disabled="disabledFn" placeholder="员工所在小组"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row class="vd_rang vg_partition">
        <el-col :md="4" align="center"> 数据可见范围<span class="vg_deep_red">(必选)</span>： </el-col>
        <el-col :md="18">
          <el-card shadow="never" style="display: flex; justify-content: center; height: 400px; overflow: auto">
            <el-row>
              <el-col :md="16" class="vg_mb_8">
                <el-col :offset="8">
                  <el-button :disabled="disabled" size="mini" type="primary" @click="setValue(0)">全部全局</el-button>
                  <el-button :disabled="disabled" size="mini" type="primary" @click="setValue(1)">全部部门</el-button>
                  <el-button :disabled="disabled" size="mini" type="primary" @click="setValue(2)">全部小组</el-button>
                  <el-button :disabled="disabled" size="mini" type="primary" @click="setValue(3)">全部个人</el-button>
                </el-col>
              </el-col>
            </el-row>
            <el-tree style="width: 100%" :data="permRangeList" node-key="perm_id" :empty-text="'暂无分配权限'" default-expand-all :expand-on-click-node="false">
              <el-row class="custom-tree-node" slot-scope="{ node, data }">
                <el-col :md="16">
                  <el-col :md="8">{{ data.perm_name }}</el-col>
                  <el-radio-group :disabled="disabled" v-model="data.user_scope">
                    <el-radio :label="0">全局</el-radio>
                    <el-radio :label="1">部门</el-radio>
                    <el-radio :label="2">小组</el-radio>
                    <el-radio :label="3">个人</el-radio>
                  </el-radio-group>
                </el-col>
                <el-col :md="8" v-if="data.user_scope === 1">
                  <el-select :disabled="disabled" collapse-tags size="mini" filterable multiple v-model="data.dept_id_list" placeholder="请选择部门" clearable>
                    <el-option v-for="item in deptList" :key="item.label" :label="item.label" :value="item.value"> </el-option>
                  </el-select>
                </el-col>
              </el-row>
            </el-tree>
          </el-card>
          <!--          <el-checkbox-group v-model="userForm.deleteScop">
            <el-checkbox
              v-for="(item, index) in userForm.permissions"
              :label="{ perm_id: item.perm_id, user_scope: userForm.deleteScop[index].user_scope }"
            >
              {{ userForm.deleteScop[index] }}
              {{ item.perm_id }}{{ userForm.deleteScop[index].user_scope }}
              {{ item.perm_name }}
            </el-checkbox>
          </el-checkbox-group>-->
          <!--          <el-radio-group v-model="userForm.user_scope" :disabled="disabled">-->
          <!--            <el-radio :label="item.value" :key="item.value" v-for="item in isRangeData">{{ item.label }}</el-radio>-->
          <!--          </el-radio-group>-->
        </el-col>
      </el-row>
      <el-row class="vd_rang vg_partition">
        <el-col :md="4" align="center"> 特殊数据范围<span class="vg_deep_red">(VIP) </span>： </el-col>
        <el-col>
          <el-form size="mini" class="vd_horiArr" :model="userForm" :disabled="disabled">
            <div class="vd_butDiv">
              <el-button type="primary" plain size="mini" @click="addType()">添加</el-button>
            </div>
            <el-table class="vd_elTable" :data="userForm.user_scpe_list" border max-height="200" v-if="flag">
              <el-table-column label="模块名称">
                <template slot-scope="scope">
                  <el-form-item label-width="0" :prop="'user_scpe_list.' + scope.$index + '.perm_id'" :rules="[{ required: true }]">
                    <el-select
                      filterable
                      @change="selectChange(scope)"
                      v-model="userForm.user_scpe_list[scope.$index].perm_id"
                      placeholder="请选择模块名称"
                      clearable
                    >
                      <el-option v-for="item in permForm" :key="item.perm_id" :label="item.perm_name" :value="item.perm_id"> </el-option>
                    </el-select>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="类型">
                <template slot-scope="scope">
                  <el-form-item label-width="0">
                    <el-checkbox @change="viewFlagChange(scope)" v-model="userForm.user_scpe_list[scope.$index].view_flag">全局查看</el-checkbox>
                    <el-checkbox @change="editFlagChange(scope)" v-model="userForm.user_scpe_list[scope.$index].edit_flag">全局编辑</el-checkbox>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column align="center" width="150px">
                <template slot-scope="scope">
                  <el-link type="danger" @click="delType(scope)" :disabled="disabled">移除</el-link>
                </template>
              </el-table-column>
            </el-table>
          </el-form>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="4" align="center"> 账号角色分配<span class="vg_deep_red">(必选)</span>： </el-col>
        <el-col>
          <div style="text-align: center">
            <el-transfer
              style="text-align: left; display: inline-block"
              v-model="value"
              :titles="['角色总表', '角色赋表']"
              :button-texts="['去掉角色', '给予角色']"
              :data="roleList"
            >
            </el-transfer>
          </div>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { userAPI } from '@api/modules/user';
import { openAPI } from '@api/modules/open';
import { stffAPI } from '@api/modules/staff';
import { roleAPI } from '@api/modules/role';
import { permAPI } from '@api/modules/perm';
import { staffTableHeader } from '@/views/component/tableHeader/staffTableHeader';
import selectInput from '@/views/component/selectInput';
import { getDept } from '@api/public';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'UserEdit',
  components: {
    selectInput
  },
  data() {
    return {
      disabled: true,
      disabledFn: true,
      userForm: {
        user_id: null,
        user_name: '',
        user_scope: 3,
        user_role_id_list: [],
        dyj_user_id: 1
      },
      stffForm: {
        stff_name: '',
        stff_id: Number,
        dept_id: Number,
        dept_team_id: Number
      },
      btn: {},
      roleData: [],
      rowSelect: [],
      permForm: [],
      copyUserForm: [],
      value: [],
      roleList: [],
      password: '123456',
      selTableData: [],
      staffTableHeader: [],
      flag: true,
      loadingFlag: true,
      synchronizationFlag: false,
      isRangeData: [
        {
          value: 0,
          label: '全部'
        },
        {
          value: 1,
          label: '部门'
        },
        {
          value: 2,
          label: '小组'
        },
        {
          value: 3,
          label: '个人'
        }
      ],
      rules: {},
      deptList: []
    };
  },
  created() {
    this.initData();
  },
  computed: {
    /**
     * 计算属性: permRangeList
     * 功能: 根据当前选中的角色ID列表(this.value)，从角色权限数据(this.roleData)中提取所有独特的权限项，
     *       并确保每个权限（依据perm_id）在结果中只出现一次。最后，以数组形式返回这些唯一权限对象。
     * 返回值: 包含所有唯一权限对象的数组。
     */
    permRangeList() {
      const map = new Map();
      this.value.forEach(item =>
        this.roleData
          .find(x => x.role_id === item)
          ?.permissions.forEach(subItem => {
            if (!map.has(subItem.perm_id)) map.set(subItem.perm_id, subItem);
          })
      );
      return Array.from(map.values());
    }
  },
  methods: {
    //启用编辑
    openEdit() {
      this.disabled = false;
      this.roleAble();
    },
    // 控制穿梭表开关
    roleAble() {
      for (let i = 0; i < this.roleList.length; i++) {
        if (this.disabled === true) {
          this.roleList[i].disabled = true;
        } else {
          this.roleList[i].disabled = false;
        }
      }
    },
    //取消编辑
    closeEdit() {
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.disabled = true;
          this.roleAble();
          this.initData();
          this.$message({
            type: 'info',
            message: '已撤销编辑!'
          });
        })
        .catch(() => {});
    },
    async initData() {
      if (this.$route.query.form_id) {
        this.loadingFlag = true;
        await this.getUserInfo();
        await this.getSelectTable();
        this.userForm.user_id = Number(this.$route.query.form_id);
        this.staffTableHeader = staffTableHeader;
        this.roleList = [];
        this.disabled = true;
        await this.roleAble();
        await this.getPerms();
        await this.getAllRoles();
        this.deptList = await getDept();
      }
    },
    // 获取账号信息
    getUserInfo() {
      get(userAPI.getUserById, { user_id: Number(this.$route.query.form_id) })
        .then(res => {
          if (res.data.code === 0) {
            this.flag = false;
            this.userForm = res.data.data.form;
            this.btn = res.data.data.btn;
            this.copyUserForm = JSON.parse(JSON.stringify(this.userForm.user_scpe_list));
            for (let i = 0; i < this.copyUserForm.length; i++) {
              this.copyUserForm[i].destroy_flag = 1;
            }
            this.stffForm.stff_name = this.userForm.stff_name;
            this.stffForm.dept_name = this.userForm.dept_name;
            this.stffForm.dept_team_name = this.userForm.dept_team_name;
            this.stffForm.stff_id = this.userForm.stff_id;
            this.stffForm.dept_id = this.userForm.dept_id;
            this.stffForm.dept_team_id = this.userForm.dept_team_id;
            if (this.value.length === 0) {
              this.value = this.helper.toArray(this.userForm.user_role_ids);
            }
            for (let i = 0; i < this.userForm.user_scpe_list.length; i++) {
              if (this.userForm.user_scpe_list[i].edit_flag === 0 || this.userForm.user_scpe_list[i].edit_flag === null) {
                this.userForm.user_scpe_list[i].edit_flag = false;
              } else if (this.userForm.user_scpe_list[i].edit_flag === 1) {
                this.userForm.user_scpe_list[i].edit_flag = true;
              }
              if (this.userForm.user_scpe_list[i].view_flag === 0 || this.userForm.user_scpe_list[i].view_flag === null) {
                this.userForm.user_scpe_list[i].view_flag = false;
              } else if (this.userForm.user_scpe_list[i].view_flag === 1) {
                this.userForm.user_scpe_list[i].view_flag = true;
              }
            }
            if (this.synchronizationFlag) {
              let password = this.userForm.user_pass;
              let vrpKey = UrlEncode.encode(
                JSON.stringify({
                  userPass: password
                })
              );
              // window.open(`http://localhost:8885/vyj/login?userName=${this.userForm.user_name}&userPass=${vrpKey}&page=1&formId=${this.userForm.dyj_user_id}&creatime=${this.userForm.create_time}`)
              // window.open(`https://www.petsprod.com/vyj/login?userName=${this.userForm.user_name}&userPass=${vrpKey}&page=1&formId=${this.userForm.dyj_user_id}&creatime=${this.userForm.create_time}`)
              // get(userAPI.userLogout).then(res => {
              //   if (res.data.code === 0) {
              //     this.$cookies.remove('userInfo')
              //     this.$cookies.remove('push')
              //   }
              // })
            }
            this.$nextTick(() => {
              this.flag = true;
            });
            setTimeout(() => {
              this.loadingFlag = false;
            }, 500);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    //获取权限信息
    getPerms() {
      get(permAPI.getAllPerms)
        .then(res => {
          if (res.data.code === 0) {
            let permForm = res.data.data;
            for (let i = 0; i < permForm.length; i++) {
              if (permForm[i].perm_child_list !== null) {
                for (let j = 0; j < permForm[i].perm_child_list.length; j++) {
                  // if(permForm[i].perm_child_list[j].perm_id === 103 || permForm[i].perm_child_list[j].perm_id === 109){
                  let temp = {};
                  temp.perm_id = permForm[i].perm_child_list[j].perm_id;
                  temp.perm_name = permForm[i].perm_child_list[j].perm_name;
                  this.permForm.push(temp);
                }
                // }
              }
            }
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 恢复账号
    recovery() {
      post(userAPI.restoreUser, { user_id: this.userForm.user_id })
        .then(res => {
          if (res.data.code === 0) {
            this.$message({ message: '恢复成功', type: 'success' });
            this.getUserInfo();
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(() => {});
    },
    // 同步dyj
    synchronization() {
      post(openAPI.userToDyj, { user_id: this.userForm.user_id })
        .then(res => {
          if (res.data.code === 0) {
            this.$message({ message: '同步成功', type: 'success' });
            this.getUserInfo();
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(() => {});
    },
    // 同步mes
    synchronizationS() {
      post(openAPI.userToMes, { user_id: this.userForm.user_id })
        .then(res => {
          if (res.data.code === 0) {
            this.$message({ message: '同步成功', type: 'success' });
            this.getUserInfo();
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(() => {});
    },
    // 同步登录
    synchronizationLogin() {
      this.synchronizationFlag = true;
      this.synchronization();
    },
    //密码重置提醒
    resetPassBox() {
      this.$confirm('是否重置密码?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      }).then(() => {
        this.resetPass();
      });
    },
    // 重置密码
    resetPass() {
      this.userForm.user_id = Number(this.$route.query.form_id);
      post(userAPI.resetUserPass, { user_id: this.userForm.user_id }).then(res => {
        if (res.data.code === 0) {
          let mg = res.data.msg;
          let tp = 'success';
          this.$message({ message: mg, type: tp });
        }
      });
    },
    // 获取传递selTableData
    getSelectTable() {
      get(stffAPI.getAllStffsV1).then(res => {
        this.selTableData = res.data.data;
      });
    },
    // 获取双击selTableData的row
    getSelTableRow(val) {
      this.stffForm = val;
    },
    // 获取all角色信息
    getAllRoles() {
      get(roleAPI.getAllRolesV1).then(res => {
        if (res.data.code === 0) {
          if (this.roleList.length === 0) {
            this.roleData = res.data.data;
            this.roleData.forEach(item => {
              if (this.userForm.user_role_ids.includes(item.role_id + '')) {
                item.permissions.forEach(permission => {
                  let find = this.userForm.userScopePerms.find(x => x.perm_id === permission.perm_id);
                  permission.user_scope = find?.user_scope ?? 3;
                  this.$set(permission, 'dept_id_list', find?.dept_id_list ?? []);
                });
              }
            });
            for (let i = 0; i < this.roleData.length; i++) {
              this.roleList.push({
                key: this.roleData[i].role_id,
                label: this.roleData[i].role_name,
                disabled: this.disabled
              });
            }
          }
        }
      });
    },
    // 提交保存
    submit() {
      this.$confirm('确认保存？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          if (this.value.length === 0) {
            let mg = '请选择至少一个角色';
            let tp = 'warning';
            this.$message({ message: mg, type: tp });
          } else if (this.stffForm.stff_name === '') {
            let mg = '请选择员工姓名！';
            let tp = 'warning';
            this.$message({ message: mg, type: tp });
          } else {
            this.saveInfo();
          }
        })
        .catch(() => {
          this.$message.info('已取消保存');
        });
    },
    // 全局查看和全局编辑影响
    viewFlagChange(scope) {
      if (scope.row.perm_id !== null) {
        if (!scope.row.view_flag) {
          scope.row.edit_flag = false;
        }
      } else {
        scope.row.edit_flag = false;
        scope.row.view_flag = false;
      }
    },
    editFlagChange(scope) {
      if (scope.row.perm_id !== null) {
        if (scope.row.edit_flag) {
          scope.row.view_flag = true;
        }
      } else {
        scope.row.edit_flag = false;
        scope.row.view_flag = false;
      }
    },
    // 特殊数据范围添加
    addType() {
      let item = {
        perm_id: null,
        view_flag: null,
        edit_flag: null
      };
      this.userForm.user_scpe_list.push(item);
    },
    // 特殊数据范围添加删除
    delType(scope) {
      this.userForm.user_scpe_list.splice(scope.$index, 1);
    },
    // select变化提示
    selectChange(val) {
      if (val.row.perm_id !== null) {
        for (let i = 0; i < this.userForm.user_scpe_list.length; i++) {
          if (i !== val.$index) {
            if (this.userForm.user_scpe_list[i].perm_id === val.row.perm_id) {
              val.row.perm_id = null;
              this.$message({
                type: 'warning',
                message: '账套选择重复'
              });
            }
          }
        }
      }
    },
    // 保存
    saveInfo() {
      const userEditBody = {};
      userEditBody.user_id = this.userForm.user_id;
      userEditBody.stff_name = this.stffForm.stff_name;
      userEditBody.stff_id = this.stffForm.stff_id;
      userEditBody.dept_id = this.stffForm.dept_id;
      userEditBody.dept_team_id = this.stffForm.dept_team_id;
      userEditBody.user_scope = this.userForm.user_scope;
      userEditBody.user_role_id_list = this.value;
      userEditBody.userScopePerms = this.permRangeList;
      let userForm = {};
      userForm = Object.assign(userForm, this.userForm);
      let copyUserForm = this.copyUserForm.filter(item => this.userForm.user_scpe_list.every(item1 => item.user_scpe_id != item1.user_scpe_id));
      userForm.user_scpe_list = userForm.user_scpe_list.concat(copyUserForm);
      userEditBody.user_scpe_list = userForm.user_scpe_list;
      for (let i = 0; i < userEditBody.user_scpe_list.length; i++) {
        if (userEditBody.user_scpe_list[i].edit_flag === true) {
          userEditBody.user_scpe_list[i].edit_flag = 1;
        } else if (userEditBody.user_scpe_list[i].edit_flag === false || userEditBody.user_scpe_list[i].edit_flag === null) {
          userEditBody.user_scpe_list[i].edit_flag = 0;
        }
        if (userEditBody.user_scpe_list[i].view_flag === true) {
          userEditBody.user_scpe_list[i].view_flag = 1;
        } else if (userEditBody.user_scpe_list[i].view_flag === false || userEditBody.user_scpe_list[i].view_flag === null) {
          userEditBody.user_scpe_list[i].view_flag = 0;
        }
      }
      post(userAPI.editUser, userEditBody)
        .then(res => {
          if (res.data.code === 0) {
            let mg = res.data.msg;
            let tp = 'success';
            this.$message({ message: mg, type: tp });
            this.permForm = [];
            this.initData();
          } else if (res.data.code === 999) {
            let mg = res.data.msg;
            let tp = 'success';
            this.$message({ message: mg, type: tp });
            this.permForm = [];
            this.initData();
          } else {
            let mg = res.data.msg;
            let tp = 'success';
            this.$message({ message: mg, type: tp });
            this.permForm = [];
            this.initData();
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'success';
          this.$message({ message: mg, type: tp });
          this.permForm = [];
          this.initData();
        });
    },
    setValue(val) {
      const rolePermissionsMap = this.roleData.reduce((acc, role) => {
        acc[role.role_id] = role.permissions;
        return acc;
      }, {});

      this.value.forEach(roleId => {
        rolePermissionsMap[roleId]?.forEach(permission => {
          permission.user_scope = val;
        });
      });
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .el-card__body {
  width: 100%;
}
.vd_horiArr {
  width: 50%;
  text-align: center;
  margin-left: 20%;
  display: flex;
  justify-content: space-around;
}
.vd_butDiv {
  width: 20%;
}
.vd_elTable {
  width: 500px;
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  padding-right: 8px;
}
</style>
