import topic from '../topic';
import { getNoCatch, postNoCatch } from '@api/request';

export const openAPI = {
  userToDyj: topic.openTopic + '/dyj/api/user_to_dyj',
  fequToDyj: topic.openTopic + '/dyj/api/fequ_to_dyj',
  backDyjRequ: topic.openTopic + '/dyj/api/backDyjRequ',
  getDdyjQutas: topic.openTopic + '/dyj/api/get_dyj_qutas',
  createFcpAccount: topic.openfcpTopic + '/create_fcp_account',
  userToMes: topic.openTopic + '/mes/api/user_to_mes',
  getMtrls: topic.openTopic + '/dyj/api/get_mtrls',
  editRequStatus: params => postNoCatch(`${topic.openTopic}/dyj/api/editRequStatus`, params),
  editRequDocu: params => postNoCatch(`${topic.openTopic}/dyj/api/editRequDocu`, params),
  getSconDataBoard: params => getNoCatch(`${topic.openTopic}/api/getSconDataBoard`, params),
  getPodrDataBoard: params => getNoCatch(`${topic.openTopic}/api/getPodrDataBoard`, params),
  getModrDataBoard: params => getNoCatch(`${topic.openTopic}/api/getModrDataBoard`, params),
  getTranDataBoard: params => getNoCatch(`${topic.openTopic}/api/getTranDataBoard`, params),
  getMyLogList: params => getNoCatch(`/api/myLog/getMyLogList`, params),
  getMyLog: params => getNoCatch(`/api/myLog/getMyLog`, params)
};
